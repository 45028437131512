@use '~@angular/material' as mat;
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

// always include only once per project
@include mat.core();

@import 'theme.scss';

html {
  height: 100%;
}

.my-theme {
  // use our theme with angular-material-theme mixin
  @include mat.all-component-themes($my-theme);
}

html, body {
  overflow-x: hidden; // do not scroll if mobile-menu
}

body {
  background-color: white;
  color: #57A0FF;
  height: 100%;
  margin: 0;
  font-family: 'Helvetica', 'Arial', sans-serif;
}

.ng-scroll-content {
  display: flex !important;
  height: 100%;
  flex-direction: column;
}

app-start,
app-gallery,
app-artist,
app-gallery-overview,
app-login, app-upload,
app-features,
app-image-detail,
app-user-management,
app-image-list {
  flex: auto;
}

.no-scroll {
  app-gallery, app-image-list {
    overflow: hidden;
  }
}

.content-align-middle {
  flex: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin: 0 100px 50px 50px;
}

mat-progress-bar {
  position: absolute !important;
}

@media only screen and (max-width: 700px) {
  .content-align-middle {
    margin: 10px 10px 10px 10px;
  }
}

h1 {
  margin: 50px 100px 50px 50px;
  letter-spacing: 10px;
  font-family: 'Helvetica Neue';
  text-transform: uppercase;
  font-weight: 300;
  text-align: center;
  color: #191a1a;
}

@media only screen and (max-width: 700px) {
  h1 {
    margin: 30px 0;
    letter-spacing: 5px;
  }
}

h2 {
  margin: 30px;
  font-weight: 300;
}

.tooltip {
  font-size: 16px;

}

.icon {
  display: flex;
  position: relative;
  z-index: 4;
  height: 20px;
  width: 20px;
  justify-content: center;
  align-items: center;
  color: #2f2f2f;

  &::before {
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.5);
  }

  &.marked {
    display: block;
  }
}

.button-save {
  position: relative;
  margin: 5px;
  height: 20px;
  width: 20px;
  bottom: 5px;
  padding: 5px;

  &::before {
    color: green;
  }
}

.btn-info {
  color: #fff;
  background-color: #57A0FF;
  border-color: #57A0FF;
}

.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #A3CBFF;
  border-color: #A3CBFF;
}

.cursor-pointer {
  cursor: pointer;
}

img {
  cursor: pointer;
  position: relative;
  z-index: 3;
}

.delete-icon {
  position: absolute;
  top: 3px;
  right: 2px;
  color: darkred;
  cursor: pointer;
}

.info-icon {
  position: absolute;
  height: 18px;
  width: 18px;
  bottom: 7px;
  right: 7px;
  color: black;
  border-radius: 50%;
  background-color: #E6F1FF;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.5);

  &::before {
    font-size: 0.7rem;
  }
}

.comment-icon {
  display: block;
  position: absolute;
  z-index: 20;
  top: 25px;
  right: 1px;
  cursor: pointer;

  .comment-modal {
    display: none;
    width: 300px;
    border-radius: 5px;
    background: dodgerblue;
  }

  &.comment-open {
    .comment-modal {
      display: block;
    }
  }
}

.bookmark-icon {
  cursor: pointer;
  display: none;
  top: 0;
  left: 2px;
  position: absolute;
  z-index: 7;
  transition: all 0.2s ease-out;


  &:hover {
    transform: scale(1.3);
    transition: all 0.2s ease-out;
  }

  &::before {
    top: 0;
    color: rgba(255, 0, 0, .5);
    font-size: 20px;
    line-height: 27px;
  }
}

.red-marked {
  &::before {
    color: red;
  }

  display: block;
}

.aperture-icon {
  cursor: pointer;
  top: 22px;
  left: 2px;
  position: absolute;
  z-index: 30;


  .exif-data {
    font-size: 12px;
    position: absolute;
    z-index: 10;
    display: none;
    top: 20px;
    left: 10px;
    width: 200px;
    min-height: 50px;
    background-color: beige;
  }

  &.open-exif {
    .exif-data {
      display: block;
    }
  }
}

.padding-10 {
  padding: 10px;
}

.margin-bottom-50 {
  margin-bottom: 50px;
}

.padding-left-10 {
  padding-left: 10px;
}

.padding-bottom-10 {
  padding-bottom: 10px;
}

.white-space-no-wrap {
  white-space: nowrap;
}

.flex-align-items-center {
  display: flex;
  align-items: center;
}

.flex-justify-center {
  display: flex;
  justify-content: center;
}

.wide-300 {
  width: 300px;
}

.wide-800 {
  width: 800px;
}

.wide-50 {
  width: 50px;
}

.wide-100 {
  width: 100px;
}

.margin-left-10 {
  margin-left: 10px;
}

.flex-space-between-baseline {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.mat-form-field-infix {
  width: 100% !important;
}

.mat-error {
  font-size: 14px;
}

.my-theme button[mat-button] {
  position: absolute;
  z-index: 1000;
  height: 48px;
  width: 48px;
  margin: 20px 20px;
  border-radius: 10px 20px;
  min-width: 48px;
  background: rgba(255, 255, 255, 0.5);
  padding: 0;

  &.active {
    background-color: rgba(231, 231, 231, 0.9);
    border: 1px solid rgba(26, 26, 26, 0.5);
  }

  &.hover {
    background-color: rgba(200, 200, 200, 0.9);
  }

  mat-icon {
    height: 33px;
    width: 32px;
    font-size: 32px;
    color: #191a1a;
  }
}

.my-theme .mat-table {
  background: none;
}

.my-theme .mat-elevation-z8 {
  box-shadow: none;
}

.display-none {
  display: none;
}

.visibility-hidden {
  visibility: hidden;
}

.position-absolute {
  position: absolute;
}
