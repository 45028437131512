@use '~@angular/material' as mat;
// define 3 theme color
$mat-dark-blue: (
  50: #191a1a,
  100: #3e4142,
  200: #62696d,
  300: #90a4ae,
  400: #78909c,
  500: #42e30d,
  600: #546e7a,
  700: #455a64,
  800: #37474f,
  900: #263238,
  A100: #393b3d,
  A200: #5b6165,
  A400: #78909c,
  A700: #455a64,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: rgba(black, 0.87),
    A200: rgba(black, 0.87),
    A400: white,
    A700: white,
  )
);

// mat-palette accepts $palette-name, main, lighter and darker variants
$my-theme-primary: mat.define-palette($mat-dark-blue, 700, 300, 900);
$my-theme-accent: mat.define-palette($mat-dark-blue);
$my-theme-warn: mat.define-palette(mat.$deep-orange-palette, A200);

// create theme (use mat-dark-theme for themes with dark backgrounds)
$my-theme: mat.define-light-theme(
    $my-theme-primary,
    $my-theme-accent,
    $my-theme-warn
);

.mat-header-cell {
  font-size: 16px;
}

.mat-cell {
  font-size: 16px;
}

.mat-card-subtitle,
.mat-card-content {
  font-size: 16px;
}

button.mat-button-base {
  font-size: 16px;
}

.my-theme .mat-form-field {
  width: 100%;

  &.mat-focused {
    .mat-form-field-label {
      color: #57A0FF !important;
    }

    .mat-form-field-ripple {
      background-color: #57A0FF !important;
    }
  }

  .mat-form-field-label, .mat-form-field-label.mat-focused,
  .mat-form-field.mat-focused.matform-field-should-float {
    color: #4c4c4c !important;
  }

  .mat-form-field-underline, .mat-form-field-underline.mat-focused {
    background-color: #57A0FF;
  }
}

.my-theme .mat-input-element:disabled, .my-theme .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 1) !important;
}

.my-theme .mat-form-field-appearance-legacy .mat-form-field-label {
  font-size: 18px !important;
}

.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label, .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(0.75) !important;
}

.my-theme .mat-card {
  background: #fcfcfc !important;
}

.my-theme .mat-toolbar {
  background-size: cover !important;
}

.my-theme .mat-input-element {
  caret-color: #333 !important;
}
